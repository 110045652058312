import React from "react";
import { ListGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faFileAlt,
    faFileArchive,
    faSignInAlt,
    faUpload,
    faUsers
} from "@fortawesome/fontawesome-free-solid";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {Timeline} from 'react-twitter-widgets'


export default function AsideList() {
    const {journalDetail}
        = useSelector(state => state?.CommonJournalReducer);

    return (
        <div className="mb-3">
            <ListGroup className="fs-6 list2">
                <ListGroup.Item >
                    <h6>Publishing collaboration</h6>
                    <div className="row">
                        <div className="col">
                            <a href="https://wams.online" target="_blank"><img src={`${process.env.PUBLIC_URL}/LOGO-WAMS-WIDE-circle-600x192.png`}  className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://medliber.com"  target="_blank"><img src={`${process.env.PUBLIC_URL}/mediliber-logo2.png`} className="img-fluid"/></a>
                        </div>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item as={Link} to="/auth/register" className="bg-theme-color text-white"><FontAwesomeIcon icon={faUpload}
                                                                                       className="w-25"/> Submit
                    Article <FontAwesomeIcon className="float-end" icon={faArrowRight}/></ListGroup.Item>
                <ListGroup.Item as={Link} to={`/journal/author-guidelines`}><FontAwesomeIcon
                    icon={faFileAlt}
                    className="w-25"/> Author
                    guidelines</ListGroup.Item>
                <ListGroup.Item as={Link} to={`/journal/editorial-board`}><FontAwesomeIcon
                    icon={faUsers}
                    className="w-25"/> Editorial
                    board</ListGroup.Item>
                <ListGroup.Item as={Link}
                                to={`/journal/abstracting-and-indexing`}><FontAwesomeIcon
                    icon={faFileArchive} className="w-25"/> Abstracting & Indexing</ListGroup.Item>
                <ListGroup.Item className="bg-secondary text-white"><FontAwesomeIcon icon={faSignInAlt}
                                                                                     className="pe-2"/> Sign up for
                    content
                    alerts</ListGroup.Item>
            </ListGroup>
            {journalDetail?.twitter_feed &&
                <div className="mt-3">
                    <Timeline
                        dataSource={{
                            sourceType: 'url',
                            url: journalDetail?.twitter_feed
                        }}
                        options={{
                            height: '250'
                        }}
                    />
                </div>
            }
        </div>

    )
}